<template>
  <DataTable
    :value="dataFormatted"
    v-model:selection="selectedBooking"
    selectionMode="single"
    dataKey="id"
    :paginator="true"
    :rows="10"
    :loading="loading"
    :filters="filters"
    removableSort
    class="p-datatable-responsive p-input-filled"
    ref="dt"
    sortField="datetime"
    :sortOrder="-1"
  >
    <template #header>
      <div class="table-header">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global']" placeholder="Global Search" />
        </span>
        <Button
          icon="pi pi-external-link"
          label="Export"
          @click="exportCSV($event)"
        />
      </div>
    </template>
    <template #empty> No bookings found. </template>
    <template #loading> Loading... Please wait. </template>
    <Column
      :sortable="true"
      field="name"
      header="Name"
      filterMatchMode="contains"
    >
      <template #body="slotProps">
        <span class="p-column-title">Name</span>
        <span>{{ slotProps.data.name }}</span>
      </template>
      <template #filter>
        <InputText
          type="text"
          v-model="filters['name']"
          class="p-column-filter"
          placeholder="Name"
        />
      </template>
    </Column>
    <Column
      :sortable="true"
      field="project.name"
      header="Project"
      filterMatchMode="contains"
    >
      <template #body="slotProps">
        <span class="p-column-title">Project</span>
        <span v-if="slotProps.data.project">
          {{ slotProps.data.project.name }}
        </span>
      </template>
      <template #filter>
        <InputText
          type="text"
          v-model="filters['project.name']"
          class="p-column-filter"
          placeholder="Project"
        />
      </template>
    </Column>
    <Column
      :sortable="true"
      field="datetime"
      header="Date"
      filterMatchMode="custom"
      :filterFunction="filterDate"
    >
      <template #body="slotProps">
        <span class="p-column-title">Date/Time</span>
        <span class="date">{{ slotProps.data.datetime }}</span>
      </template>
      <template #filter>
        <Calendar
          v-model="filters['datetime']"
          dateFormat="yy-mm-dd"
          class="p-column-filter"
          placeholder="Date"
          :showButtonBar="true"
        />
      </template>
    </Column>
    <Column
      :sortable="true"
      field="amount"
      header="Patients"
      filterMatchMode="contains"
      bodyClass="col-center"
    >
      <template #body="slotProps">
        <span class="p-column-title">Patients</span>
        <span>{{ parseInt(slotProps.data.amount) }}</span>
      </template>
      <template #filter>
        <InputText
          type="text"
          v-model="filters['amount']"
          class="p-column-filter"
          placeholder="Patients"
        />
      </template>
    </Column>
    <Column
      :sortable="true"
      field="location.name"
      header="Location"
      filterMatchMode="contains"
    >
      <template #body="slotProps">
        <span class="p-column-title">Location</span>
        <span>{{ slotProps.data.location.name }}</span>
      </template>
      <template #filter>
        <InputText
          type="text"
          v-model="filters['location.name']"
          class="p-column-filter"
          placeholder="Location"
        />
      </template>
    </Column>
    <Column
      field="testers"
      header="Collectors"
      filterMatchMode="custom"
      :filterFunction="filterTesters"
    >
      <template #body="slotProps">
        <span class="p-column-title">Collectors</span>
        <ul class="ul-testers p-m-0 p-p-0" v-if="slotProps.data.testers.length">
          <li v-for="tester in slotProps.data.testers" :key="tester">
            {{ tester }}
          </li>
        </ul>
      </template>
      <template #filter>
        <InputText
          type="text"
          v-model="filters['testers']"
          class="p-column-filter"
          placeholder="Email"
        />
      </template>
    </Column>
    <Column
      :sortable="true"
      field="covid"
      header="Covid"
      filterMatchMode="contains"
      bodyClass="col-center"
    >
      <template #body="slotProps">
        <span class="p-column-title">Covid</span>
        <i v-if="slotProps.data.covid" class="pi pi-check"></i>
      </template>
      <template #filter>
        <Dropdown
          v-model="filters['covid']"
          :options="[true, false]"
          placeholder="Covid"
          class="p-column-filter"
          :showClear="true"
        >
          <template #option="slotProps">
            <span :class="'customer-badge status-' + slotProps.option">
              {{ slotProps.option }}
            </span>
          </template>
        </Dropdown>
      </template>
    </Column>
    <Column
      :sortable="true"
      field="conflict"
      header="Conflict"
      filterMatchMode="contains"
      bodyClass="col-center"
    >
      <template #body="slotProps">
        <span class="p-column-title">Conflict</span>
        <i v-if="slotProps.data.conflict" class="pi pi-check"></i>
      </template>
      <template #filter>
        <Dropdown
          v-model="filters['conflict']"
          :options="[true]"
          placeholder="Covid"
          class="p-column-filter"
          :showClear="true"
        >
          <template #option="slotProps">
            <span :class="'customer-badge status-' + slotProps.option">
              {{ slotProps.option }}
            </span>
          </template>
        </Dropdown>
      </template>
    </Column>
    <Column
      :sortable="true"
      field="status"
      header="Status"
      filterMatchMode="contains"
    >
      <template #body="slotProps">
        <span class="p-column-title">Status</span>
        <span :class="'customer-badge status-' + slotProps.data.status">
          {{ slotProps.data.status }}
        </span>
      </template>
      <template #filter>
        <Dropdown
          v-model="filters['status']"
          :options="statuses"
          placeholder="Status"
          class="p-column-filter"
          :showClear="true"
        >
          <template #option="slotProps">
            <span :class="'customer-badge status-' + slotProps.option">
              {{ slotProps.option }}
            </span>
          </template>
        </Dropdown>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import firebase from 'firebase/app'
import { formatDate } from '@/helpers/date'
import { statuses } from '@/helpers/constants'

export default {
  name: 'BookingsTable',
  components: { DataTable, Column, Button, InputText, Calendar, Dropdown },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const selectedBooking = ref(null)
    const router = useRouter()

    watch(
      () => selectedBooking.value,
      newVal => {
        router.push({
          name: 'UpdateBooking',
          params: { id: newVal.id }
        })
      }
    )

    const dataFormatted = computed(() => {
      return props.data.map(row => {
        let datetime = row.datetime
        let testers = row.testers

        if (row.datetime instanceof firebase.firestore.Timestamp)
          datetime = formatDate({ date: row.datetime.toDate() })

        if (row.testers.length && typeof row.testers[0] !== 'string')
          testers = row.testers.map(tester => tester.email)

        return {
          ...row,
          datetime,
          testers
        }
      })
    })

    function filterDate(value, filter) {
      if (
        filter === undefined ||
        filter === null ||
        (typeof filter === 'string' && filter.trim() === '')
      ) {
        return true
      }

      if (value === undefined || value === null) {
        return false
      }

      return value === formatDate({ date: filter })
    }

    const dt = ref(null)

    function exportCSV() {
      dt.value.exportCSV()
    }

    function filterTesters(value, filter) {
      if (filter) {
        const search = value.filter(row => {
          return row.indexOf(filter) === -1 ? false : true
        })
        return search.length > 0
      }
      return true
    }

    const filters = ref({})

    return {
      filters,
      dataFormatted,
      filterDate,
      formatDate,
      exportCSV,
      filterTesters,
      statuses,
      dt,
      selectedBooking
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/table.scss';
@import '@/scss/badges.scss';
.p-datatable {
  ::v-deep(.col-center) {
    text-align: center;
  }
  ::v-deep(.p-dropdown-item) {
    padding: 0.25rem 0.5rem;
  }
}
.ul-testers {
  list-style: none;
  display: inline-grid;
}
.p-calendar {
  ::v-deep(.p-datepicker) {
    td {
      padding: 0;
      span {
        height: 2rem;
        width: 2rem;
      }
    }
    .p-datepicker-buttonbar {
      padding: 0.5rem 0 0;
    }
  }
}
@media screen and (min-width: 770px) {
  .p-calendar {
    ::v-deep(.p-datepicker) {
      width: 300px;
    }
  }
}
</style>
