<template>
  <DataTable
    :value="patients"
    v-model:selection="selectedBooking"
    selectionMode="single"
    dataKey="id"
    :paginator="true"
    :rows="20"
    removableSort
    :filters="filters"
    class="p-datatable-responsive p-input-filled"
    ref="dt"
    sortField="date"
    :sortOrder="-1"
  >
    <template #header>
      <div class="table-header">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global']" placeholder="Global Search" />
        </span>
        <Button
          icon="pi pi-external-link"
          label="Export"
          @click="exportCSV($event)"
        />
      </div>
    </template>
    <Column
      v-for="{ field, header } in columns"
      :key="field"
      :field="field"
      :header="header"
      :sortable="true"
      filterMatchMode="contains"
    >
      <template v-if="field === 'status'" #filter>
        <Dropdown
          v-model="filters['status']"
          :options="statuses"
          placeholder="Status"
          class="p-column-filter"
          :showClear="true"
        >
          <template #option="slotProps">
            <span :class="'customer-badge status-' + slotProps.option">
              {{ slotProps.option }}
            </span>
          </template>
        </Dropdown>
      </template>
      <template v-else #filter>
        <InputText
          type="text"
          v-model="filters[field]"
          class="p-column-filter"
          :placeholder="header"
        />
      </template>
      <template #body="slotProps">
        <span class="p-column-title">{{ header }}</span>
        <span
          v-if="field === 'status'"
          :class="'customer-badge status-' + slotProps.data.status"
        >
          {{ slotProps.data.status }}
        </span>
        <span v-else>{{ slotProps.data[field] }}</span>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { ref, watchEffect, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { formatDate } from '@/helpers/date'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import { statuses } from '@/helpers/constants'

export default {
  components: { DataTable, Column, InputText, Button, Dropdown },
  emits: ['update', 'enable', 'delete'],
  props: {
    bookings: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const columns = ref([
      { field: 'patient', header: 'Patient' },
      { field: 'email', header: 'Email' },
      { field: 'phone', header: 'Phone' },
      { field: 'results', header: 'Results' },
      { field: 'date', header: 'Date' },
      { field: 'booking', header: 'Booking' },
      { field: 'project', header: 'Project' },
      { field: 'location', header: 'Location' },
      { field: 'status', header: 'Status' }
    ])
    const selectedBooking = ref(null)
    const router = useRouter()

    watch(
      () => selectedBooking.value,
      newVal => {
        router.push({
          name: 'UpdateBooking',
          params: { id: newVal.id }
        })
      }
    )

    const patients = ref([])

    watchEffect(() => {
      // patient table
      const patientList = []
      // loop through bookings
      props.bookings.forEach(row => {
        const booking = {
          booking: row.name,
          date: formatDate({ date: row.datetime.toDate() }),
          status: row.status,
          location: row.location.name,
          project: row.project ? row.project.name : '',
          id: row.id
        }
        // loop through patients for each booking
        row.testees.forEach(row => {
          const patient = {
            patient: `${row.firstName} ${row.lastName}`,
            email: row.email,
            phone: row.mobile,
            results: row.status
          }
          patientList.push({ ...booking, ...patient })
        })
      })
      patients.value = patientList
    })

    const dt = ref(null)
    function exportCSV() {
      dt.value.exportCSV()
    }

    const filters = ref({})

    return {
      columns,
      patients,
      statuses,
      exportCSV,
      filters,
      dt,
      selectedBooking
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/table.scss';
@import '@/scss/badges.scss';
.p-datatable {
  ::v-deep(.col-center) {
    text-align: center;
  }
  ::v-deep(.p-dropdown-item) {
    padding: 0.25rem 0.5rem;
  }
}
</style>
