<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Requested Bookings</h1>
  <div class="p-field-checkbox">
    <Checkbox id="binary" v-model="checked" :binary="true" />
    <label for="binary">View by Patient</label>
  </div>
  <BookingsTable v-if="!checked" :data="bookings" :loading="loading" />
  <BookingsPatientsTable v-else :bookings="bookings" />
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import BookingsTable from '@/components/BookingsTable.vue'
import BookingsPatientsTable from '@/components/BookingsPatientsTable.vue'
import { firestore } from '@/services/firebase'
import { useHandleError } from '@/composables/useHandleError'
import { getPermissions } from '@/helpers/permissions'
import Checkbox from 'primevue/checkbox'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  name: 'RequestedBookings',
  components: {
    BookingsTable,
    BookingsPatientsTable,
    Checkbox,
    BaseBreadcrumb
  },
  setup() {
    const bookings = ref([])
    const store = useStore()
    const loading = ref(true)
    const { canViewAllBookings, isClientAdminOnly } = getPermissions()
    const { handleError } = useHandleError()
    const checked = ref(false)
    const breadcrumbs = [{ label: 'Bookings' }]

    function getBookings() {
      const user = store.getters.user

      if (user) {
        let query = firestore.collection('bookings').orderBy('created', 'desc')

        if (!canViewAllBookings.value) {
          query = query.where('testerIds', 'array-contains', user.id)
        }

        // limit client admins to only view bookings assigned to their projects
        if (isClientAdminOnly.value)
          query = query.where(
            'project.id',
            '==',
            user.projects.length ? user.projects[0] : ''
          )

        query
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const booking = doc.data()
              booking.id = doc.id
              bookings.value.push(booking)
            })
            loading.value = false
          })
          .catch(error => {
            handleError(error)
            loading.value = false
          })
      }
    }

    watch(
      () => store.getters.user,
      (newVal, oldVal) => {
        if (oldVal === null) getBookings()
      }
    )

    getBookings()

    return {
      bookings,
      loading,
      checked,
      breadcrumbs
    }
  }
}
</script>
